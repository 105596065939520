.static-view {
	.inner-banner {
		&.contact {
			background-image: url(RESOURCE/img/banner_contact.webp);
		}

		&.vermieter {
			background-image: url(RESOURCE/img/banner_vermieter.webp);
		}

		&.fahrradverleih {
			background-image: url(RESOURCE/img/banner_fahrradverleih.webp);
		}

		&.fehmarn {
			background-image: url(RESOURCE/img/banner_fehmarn.webp);
		}
	}
}